<template>
  <div id="app">
    <Nav v-if="isPc" />
    <MobileNav v-if="!isPc" />
    <router-view />
  </div>
</template>

<script>
import Nav from "@/components/Pc/Nav";
import MobileNav from "@/components/Mobile/Nav";
export default {
  name: "App",
  components: {
    Nav,
    MobileNav
  },
  data() {
    return {
      isPc: false,
      screenWidth: null
    };
  },
  watch: {
    screenWidth: {
      handler(newV, oldV) {
        console.log(newV, oldV);
        this.init()
      },
      // immediate: true,
    },
  },
  mounted() {
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    _isReplace() {
      return this.$route.path;
    },
    init() {
      if (this._isMobile()) {
        // 跳转至手机端路由
        if (this._isReplace() !== "/mobile/home") {
          this.$router.replace("/mobile/home");
        }
        this.isPc = false;
      } else {
        // 跳转至 pc 端路由
        if (this._isReplace() !== "/") {
          this.$router.replace("/");
        }
        this.isPc = true;
      }
    },
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-width: 100%;
}
</style>

<template>
  <div class="index">
    <div class="box1">
      <div class="header_box">
        <div class="text">
          <div class="title1">AI唇声技术</div>
          <div class="title2">AI LIP SOUND TECHNOLOGY</div>
        </div>
        <div class="list">
          <div class="itemList">
            <img class="itemIcon" src="../../assets/item1.png" alt="" />
            <div class="itemTitle">精准识别语音，</div>
            <div class="itemTitle">去掉静音成分，</div>
            <div class="itemTitle">获取有效语音。</div>
          </div>
          <div class="itemList">
            <img class="itemIcon" src="../../assets/item2.png" alt="" />
            <div class="itemTitle">高效降噪，</div>
            <div class="itemTitle">处理音频内容，</div>
            <div class="itemTitle">ASR识别更精准，</div>
            <div class="itemTitle">减少资源浪费。</div>
          </div>
          <div class="itemList">
            <img class="itemIcon" src="../../assets/item3.png" alt="" />
            <div class="itemTitle">深度学习技术合成逼真语音</div>
            <div class="itemTitle">体验自然生动</div>
            <div class="itemTitle">速度快</div>
            <div class="itemTitle">自然流畅</div>
          </div>
          <div class="itemList">
            <img class="itemIcon" src="../../assets/item4.png" alt="" />
            <div class="itemTitle">支持多种语言方案</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="action">
        <div class="text">
          <div class="title1">AI动作捕捉</div>
          <div class="title2">AI MOTION CAPTURE</div>
        </div>
        <div class="video">
          <div class="videoTitle">
            动作捕捉是对物体运动进行记录的一种手段，从技术原理上分为光学、惯性、视觉这主要的三类。
          </div>

          <img class="new2" src="../../assets/new2.png" alt="" />
        </div>
      </div>
    </div>
    <div class="box3">
      <div class="box3Content">
        <div class="title1">音色克隆技术</div>
        <div class="title2">SOUND CLONING TECHNOLOGY</div>
        <div class="title3">
          “声音复刻”又称“声音克隆”，是语音合成技术（TTS ，Text To
          Speech）的个性化应用，用户可通过少量的录音进行模型训练，得到与用户本人在音色和发音风格上非常相似的声音模型，快速“复刻”个性化声音，该“复刻”声音可使用在讲故事、播天气、读小说、导航播报等功能场景。
        </div>
        <div class="box3Img">
          <img src="../../assets/new.png" alt="" />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Pc/Footer.vue";
export default {
  components: {
    Footer,
  },
  data() {
    return {};
  },
  mounted() {
    // const query = this.$route.query;
    // const element = document.getElementsByClassName(`box2`)[0];
    // element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
  },
};
</script>

<style lang='less' scoped>
.index {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .box1 {
    height: 800px;
    background: url("../../assets/bg5.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    .header_box {
      color: #fff;
      width: 1200px;
      .text {
        text-align: left;
        .title1 {
          font-size: 30px;
        }
        .title2 {
          font-size: 18px;
        }
      }

      .list {
        display: flex;
        margin-top: 80px;
        justify-content: space-between;
        .itemList {
          .itemIcon {
            width: 56px;
            height: 56px;
            margin-left: -10px;
            margin-bottom: 20px;
          }
          .itemTitle {
            font-size: 19px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            line-height: 35px;
            opacity: 0.7;
          }
        }
      }
    }
  }

  .box2 {
    position: relative;
    height: 800px;
    background: url("../../assets/bg2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    .action {
      width: 1200px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      .text {
        text-align: left;
        .title1 {
          font-size: 30px;
        }
        .title2 {
          font-size: 18px;
        }
      }
    }

    .video {
      display: flex;
      justify-content: space-between;
      .new2 {
        width: 450px;
        height: 450px;
      }
      .videoTitle {
        font-size: 19px;
        width: 245px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        line-height: 34px;
        text-align: left;
        text-indent: 2em;
        margin-right: 60px;
        margin-top: 20px;
      }
    }
  }

  .box3 {
    height: 673px;
    background: #121212;

    .box3Content {
      width: 1200px;
      margin: 0 auto;
      text-align: left;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      .title1 {
        font-size: 30px;
        margin-top: 100px;
      }
      .title2 {
        font-size: 18px;
      }
      .title3 {
        font-size: 18px;
        width: 750px;
        line-height: 30px;
        text-indent: 2em;
        margin-top: 30px;
      }
      .box3Img {
        width: 750px;
        display: flex;
        justify-content: center;
        margin-top: 30px;
        img {
          width: 476px;
          height: 276px;
          object-fit: fill;
        }
      }
    }
  }
}
</style>
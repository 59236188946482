<template>
  <div class="index">
    <div class="box1">
      <div class="title1">超高逼真数字虚拟人</div>
      <div class="list">
        <div class="item" v-for="(item, index) in list" :key="index">
          <img class="icon" :src="item.url" alt="" />
          <div class="item_title">{{ item.title }}</div>
        </div>
      </div>
      <div class="video">
        <video
          autoplay
          loop
          width="100%"
          src="http://171.34.173.85:12888/static/videos/human_hdr.mp4"
        ></video>
      </div>
    </div>
    <div class="box2">
      <div class="title1">AI虚拟人信息播报产品</div>
      <div class="title2">语言合成，图像处理，智能翻译等人工智能技术。</div>
      <div class="title2">多语言的新闻播报，支持文本到视频的自动输出。</div>
      <img class="img" src="../../assets/h51.png" alt="" />
    </div>
    <div class="box3">
      <div class="title1">AI虚拟人电商带货产品</div>
      <img class="img" src="../../assets/h52.png" alt="" />
      <div class="title2">
        AI机器人直播带货所具有的优势是非常明显的，首先在这一类型的直播模式中不用真人出镜，也不用真人看守，这也就意味着有很多大众可以从事自己第一职业的同时开拓自己的第二职业，既不会浪费时间成本，又不会花费太多的心血，而在收益方面甚至能够抵得上上班的收入。对于一些小型企业来说，采用该类型的带货直播模式，则意味着有效降低成本开支，提升产品销售业绩。
      </div>
    </div>
    <div class="box4">
      <div class="title1">AI虚拟人直播产品</div>
      <img class="img" src="../../assets/h53.png" alt="" />
      <div class="title2">
        在直播和短视频的庞大流量池下，利用AI虚拟人物技术+动态捕捉技术，在内容和营销上进行创新，这是一个让品牌在市场竞争中脱颖而出，抢占消费者心理的重要因素，利用虚拟形象做直播、短视频，可以更好的提升企业的品牌价值，让商家留住粉丝，刺激最终转化。
      </div>
    </div>
    <div class="itemimg">
      <div>
        <span class="title"> 联系我们 </span>
        <img src="../../assets/weix.jpg" />
      </div>
      <div>
        <span class="title"> 公众号 </span>
        <img src="../../assets/gonzhonhao.jpg" />
      </div>
      <div>
        <span class="title"> 视频号 </span>
        <img src="../../assets/weixshipinhao.jpg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      list: [
        {
          url: require("../../assets/chanpin4.png"),
          title: "具有资产的数字员工",
        },
        {
          url: require("../../assets/chanpin5.png"),
          title: "企业宣传，品牌代言官，服务官",
        },
        {
          url: require("../../assets/chanpin6.png"),
          title: "完美形象永久服务",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.index {
  min-height: 100vh;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}
.box1 {
  height: 600px;
  background: url("../../assets/bg5h5.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0 25px;
  .title1 {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    margin-top: 70px;
    text-align: left;
  }

  .list {
    .item {
      display: flex;
      align-items: center;
      margin-top: 30px;
      .icon {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }
      .item_title {
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        line-height: 23px;
        opacity: 0.7;
      }
    }
  }
  .video {
    height: 224px;
    margin-top: 60px;
  }
}
.box2 {
  height: 600px;
  background: url("../../assets/zhinengh5.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  .title1 {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    margin: 40px 0;
    text-align: left;
  }
  .title2 {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    line-height: 23px;
    opacity: 0.7;
    text-align: left;
    margin-bottom: 20px;
  }
  .img {
    margin: 20px auto;
    height: 320px;
    width: 260px;
    object-fit: fill;
  }
}

.box3 {
  height: 600px;
  background: #1a1a1a;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title1 {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    margin: 30px 0;
    width: 100%;
    text-align: left;
  }
  .img {
    width: 180px;
    height: 224px;
    object-fit: fill;
  }
  .title2 {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    line-height: 28px;
    text-align: left;
    text-indent: 2em;
    margin-top: 20px;
  }
}
.box4 {
  height: 600px;
  background: #1a1a1a;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title1 {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    margin: 30px 0;
    width: 100%;
    text-align: left;
  }
  .img {
    width: 180px;
    height: 224px;
    object-fit: fill;
  }
  .title2 {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    line-height: 28px;
    text-align: left;
    text-indent: 2em;
    margin-top: 20px;
  }
}
.itemimg {
  background-color: #1a1a1a;
  color: #fff;
  width: 100vw;
  display: flex;
  justify-content: space-between;

  img {
    width: 100px;
    height: 100px;
  }
}
</style>

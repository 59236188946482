<template>
  <div class="home">
    <div class="box1">
      <img class="homeImg1" src="../../assets/h54.png" alt="" />
      <img class="homeImg2" src="../../assets/icon.png" alt="" />
      <div class="title">
        专注提供 <br />
        数字虚拟人员工解决方案
      </div>
    </div>

    <div class="box3">
      <div class="text_box">
        <div class="title1">数字虚拟人员工四大官</div>
        <div class="title2">FOUR OFFICIALS OF DIGITAL VIRTUAL WORKERS</div>
      </div>
      <van-swipe
        :loop="false"
        :show-indicators="false"
        class="my-swipe"
        width="300"
        :autoplay="3000"
        indicator-color="white"
      >
        <van-swipe-item>
          <div class="swiperItem bg1">
            <div class="title1">
              CHIEF BRAND
              <br />
              OFFICER
            </div>
            <div class="title2">首席品牌官</div>
          </div>
        </van-swipe-item>
        <van-swipe-item>
          <div class="swiperItem bg2">
            <div class="title1">
              CHIEF
              <br />
              LIVE BROADCASTER
            </div>
            <div class="title2">首席直播官</div>
          </div>
        </van-swipe-item>
        <van-swipe-item>
          <div class="swiperItem bg3">
            <div class="title1">
              CHIEF
              <br />
              SERVICE OFFICER
            </div>
            <div class="title2">首席服务官</div>
          </div>
        </van-swipe-item>
        <van-swipe-item>
          <div class="swiperItem bg4">
            <div class="title1">
              AI INFORMATION
              <br />
              BROADCASTER
            </div>
            <div class="title2">AI信息播报官</div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="box2">
      <div class="title1">智能硬件</div>
      <div class="title2">SMART HARDWARE</div>
      <div class="title3">AI虚拟人互动大屏</div>
      <img class="img" src="../../assets/h55.png" alt="" />
    </div>
    <div class="box4">
      <div class="text_box">
        <div class="title1">核心技术</div>
        <div class="title2">CORE TECHNOLOGY</div>
      </div>
      <div class="img1_box">
        <div>
          <img class="img1" src="../../assets/htts.png" alt="" />
          <div class="span">AI唇声技术</div>
        </div>
        <div>
          <img class="img1" src="../../assets/htts2.png" alt="" />
          <div class="span">AI动作捕捉</div>
        </div>
        <div>
          <img class="img1" src="../../assets/tu3.png" alt="" />
          <div class="span">音色克隆技术</div>
        </div>
      </div>
      <div class="text_box">
        <div class="title1">专属元宇宙数字资产</div>
        <div class="title2">EXCLUSIVE METAMERS DIGITAL ASSETS</div>
      </div>
      <div class="img1_box img1_box2">
        <div>
          <img class="img2" src="../../assets/h8.png" alt="" />
          <div class="span">人物形象数字资产</div>
        </div>
        <div>
          <img class="img2" src="../../assets/h7.png" alt="" />
          <div class="span">声音数字资产</div>
        </div>
      </div>
      <div class="img1_box img1_box2">
        <div>
          <img class="img2" src="../../assets/h11.png" alt="" />
          <div class="span">人物周边（服装，发型等）</div>
        </div>
        <div>
          <img class="img2" src="../../assets/h9.png" alt="" />
          <div class="span">人物剧情数字资产</div>
        </div>
      </div>
      <div class="box4Title">
        多种解决方案 <br />
        满足不同场景的虚拟数字人应用需求
      </div>
      <div class="box4About">联系我们</div>
    </div>
    <div class="footer">
      <img class="img" src="../../assets/icon.png" alt="" />
      <div class="text_box">
        <div>商务合作：13575797493</div>
        <div>江西总部: 江西省南昌市红谷滩新区九龙大道VR产业园1号楼13楼</div>
        <div>北京分部: 北京市海淀区中关村南大街36号12号楼17层1706号</div>
        <div>湖南分部: 湖南省长沙市高新开发区麓云路100号兴工科技园12栋</div>
        <div>杭州分部：浙江省杭州市余杭区仓前街道时尚万通城3幢1607A室</div>
      </div>
    </div>
    <div class="itemimg">
      <div>
        <span class="title"> 联系我们 </span>
        <img src="../../assets/weix.jpg" />
      </div>
      <div>
        <span class="title"> 公众号 </span>
        <img src="../../assets/gonzhonhao.jpg" />
      </div>
      <div>
        <span class="title"> 视频号 </span>
        <img src="../../assets/weixshipinhao.jpg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="less" scoped>
.home {
  min-height: 100vh;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  background: #000;
}
.box1 {
  background: #000;
  height: 650px;
  background: url("../../assets/h54.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  .homeImg1 {
    object-fit: fill;
    width: 100%;
    position: absolute;
    height: 650px;
    left: 0;
    top: 0;
    z-index: 1;
  }
  .homeImg2 {
    object-fit: fill;
    width: 84px;
    height: 30px;
    position: absolute;
    left: 25px;
    top: 45px;
    z-index: 1;
  }
  .title {
    margin-top: 170px;
    font-size: 25px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #efefef;
    position: relative;
    z-index: 1;
  }
}
.box2 {
  height: 600px;
  background: url("../../assets/zhinengh5.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0 25px;
  .title1 {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    margin-top: 70px;
    text-align: left;
  }
  .title2 {
    font-size: 10px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    text-align: left;
  }
  .title3 {
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    opacity: 0.7;
    text-align: left;
    margin-top: 15px;
  }
  .img {
    width: 214px;
    height: 390px;
    margin-top: 20px;
  }
}

.box3 {
  height: 600px;
  background: url("../../assets/h70.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0 25px;
  .text_box {
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    margin: 60px 0;
    text-align: left;
    .title1 {
      font-size: 20px;
    }
    .title2 {
      font-size: 12px;
    }
  }
}

.box4 {
  height: 900px;
  background: url("../../assets/h61.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  .text_box {
    margin-top: 60px;
    color: #fff;
    font-family: Source Han Sans CN;
    font-weight: 400;
    text-align: left;
    .title1 {
      font-size: 20px;
    }
    .title2 {
      font-size: 12px;
    }
  }
  .img1_box {
    display: flex;
    margin-top: 60px;
    padding: 0 25px;
    justify-content: space-between;
    .span {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      margin-top: 20px;
    }
    .img1 {
      width: 78px;
      height: 53px;
    }
    .img2 {
      width: 54px;
      height: 54px;
    }
  }
  .img1_box2 {
    padding: 0 !important;

    div {
      width: 150px;
    }
  }
  .box4Title {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    line-height: 32px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .box4About {
    background: linear-gradient(-43deg, #6717cd 0%, #2871fa 100%);
    width: 75px;
    line-height: 22px;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    margin: 20px auto;
  }
}
.footer {
  background: url("../../assets/h60.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0 20px;
  height: 240px;
  display: flex;
  flex-direction: column;
  .img {
    margin-top: 22px;
    width: 84px;
    height: 30px;
  }
  .text_box {
    margin-top: 20px;
    font-size: 10px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    opacity: 0.46;
    text-align: left;
    div {
      margin-bottom: 10px;
    }
  }
}
.itemimg {
  color: #fff;
  width: 100vw;
  display: flex;
  justify-content: space-between;

  img {
    width: 100px;
    height: 100px;
  }
}
.swiperItem {
  width: 236px;
  height: 313px;
  padding: 20px 15px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  text-align: left;
  .title1 {
    font-size: 12px;
  }
  .title2 {
    font-size: 16px;
  }
  // img {
  //   object-fit: fill;
  //   width: 236px;
  //   height: 313px;
  // }
}
.bg1 {
  background: url("../../assets/s1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.bg2 {
  background: url("../../assets/2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.bg3 {
  background: url("../../assets/s3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.bg4 {
  background: url("../../assets/s4.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>

<template>
  <div class="index">
    <div class="box1">
      <div class="indexTitle">数字虚拟人智能大屏</div>
      <div class="box1_title">
        数字人是基于人工智能的多模态人机交互系统。她形象丰富、动作自然、
        交互方式多样；适配多终端及系统；能自主交互问答，还能实现实时真人接管；是打
        造的未来行业超级员工，为企业节省人力资源成本，满足技术创新需求，助力城市数字化转型。
      </div>
      <img class="img" src="../../assets/ai1.png" alt="" />
    </div>
    <div class="box2">
      <div class="indexTitle">数字虚拟人智能大屏</div>
      <div class="title2">9大功能介绍</div>
      <div class="list">
        <div>
          <div class="item" v-for="(item, index) in list1" :key="index">
            <img class="yuan" src="../../assets/yuan.png" alt="" />
            <div class="item_title">{{ item }}</div>
          </div>
        </div>
        <div style="margin-left: 50px">
          <div class="item" v-for="(item, index) in list2" :key="index">
            <img class="yuan" src="../../assets/yuan.png" alt="" />
            <div class="item_title">{{ item }}</div>
          </div>
        </div>
      </div>
      <img class="img2" src="../../assets/ai2.png" alt="">
    </div>
    <div class="itemimg">
      <div>
        <span class="title"> 联系我们 </span>
        <img src="../../assets/weix.jpg" />
      </div>
      <div>
        <span class="title"> 公众号 </span>
        <img src="../../assets/gonzhonhao.jpg" />
      </div>
      <div>
        <span class="title"> 视频号 </span>
        <img src="../../assets/weixshipinhao.jpg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      list1: ["问候接待", "智能讲解", "人物识别", "远程客服", "导航导览"],
      list2: ["互动问答", "信息播报", "内容推荐", "主动营销"],
    };
  },
};
</script>

<style lang="less" scoped>
.index {
  min-height: 100vh;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  .box1 {
    background: pink;
    padding: 0 25px;
    background: url("../../assets/bg5h5.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 600px;

    .img {
      width: 288px;
      height: 238px;
      margin-top: 40px;
    }
    .indexTitle {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
      margin: 35px 0;
      text-align: left;
    }
    .box1_title {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      line-height: 28px;
      text-align: left;
      text-indent: 2em;
    }
  }
  .box2 {
    height: 600px;
    background: url("../../assets/zhinengh5.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .indexTitle {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
      margin: 35px 0;
      text-align: left;
      padding-left: 25px;
    }
    .title2 {
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      margin-bottom: 20px;
    }
    .list {
      display: flex;
      justify-content: center;
      .item {
        display: flex;
        align-items: center;
        .yuan {
          width: 12px;
          height: 12px;
          margin-right: 10px;
        }
        .item_title {
          color: #fff;
          font-size: 15px;
        }
      }
    }
    .img2 {
      width: 330px;
      height: 220px;
      margin-top: 50px;
    }
  }
}
.itemimg {
  color: #fff;
  width: 100vw;
  display: flex;
  justify-content: space-between;

  img {
    width: 100px;
    height: 100px;
  }
}
</style>

<template>
  <div class="index">
    <div class="navIndex">
      <img class="iconImg" src="../../assets/icon.png" alt="小核桃" />
      <div class="nav">
        <div
          class="item"
          v-for="(item, index) in navList"
          :key="index"
          :class="item.name === currentName ? 'active' : ''"
          @click="handleNav(item.path, item.name)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        {
          title: "首页",
          path: "/",
          name: "Home",
        },
        {
          title: "产品方案",
          path: "/product",
          name: "Product",
        },
        {
          title: "核心技术",
          path: "/core",
          name: "Core",
        },
        {
          title: "智能硬件",
          path: "/intelligent",
          name: "Intelligent",
        },
        {
          title: "关于我们",
          path: "/about",
          name: "About",
        },
      ],
      currentName: "",
    };
  },
  watch: {
    "$route.name": {
      handler(newVal) {
        this.currentName = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    handleNav(path, name) {
      if (this.$route.name !== name) {
        this.$router.push({
          path,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.index {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
  .navIndex {
    width: 1200px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .iconImg {
    width: 76px;
    height: 30px;
  }
  .nav {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    .item {
      width: 100px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 20px;
    }
    .active {
      background: linear-gradient(-43deg, #6717cd 0%, #2871fa 100%) !important;
      border-radius: 6px;
    }
  }
}
</style>
<template>
  <div class="index">
    <div class="header">
      <img src="../../assets/intelligentBg.png" alt="" />
      <div class="title">公司简介</div>
    </div>
    <div class="content">
      <div class="title">
        北京小核桃科技有限公司成立于2015年12月，公司新四板挂牌代码：160464，公司荣获国家高新企业，科技型中小企业认证，公司位于中国北京市海淀区中关村南大街36号。公司团队来自于华为，中兴等知名企业，公司70%以上为研发人员。
      </div>
      <div class="title">
        我司研发打造的未来行业超级员工，为企业节省人力资源成本，满足技术创新需求，助力政府，企事业单位数字化转型。小核桃数字虚拟人基于人工智能的多模态人机交互系统。其形象丰富、动作自然、交互方式多样;适配多终端及系统;能自主交互问答，还能实现实时真人接管。数字人目前已在泛娱乐、金融、文旅等多个行业渗透应用,不同功能的数字人可赋能不同领域。
      </div>
    </div>
    <!-- 底部 -->
    <Footer/>
  </div>
</template>

<script>
import Footer from "@/components/Pc/Footer.vue";
export default {
  components: {
    Footer,
  },
  data() {
    return {};
  },
};
</script>

<style lang='less' scoped>
.index {
  background: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header {
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
  .title {
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -56px;
  }
}
.bgIndex {
  height: 356px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/intelligentBg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.content {
  padding: 40px 0 0;
  font-size: 18px;
  line-height: 34px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  width: 1200px;
  .title {
    text-indent: 2em;
    text-align: left;
  }
}

</style>
<template>
  <div class="box5">
    <div class="box5Index">
      <div class="title1">多种解决方案，满足不同场景的虚拟数字人应用需求</div>
      <div class="title2">留下联系方式，我们将为您提供一对一的资询服务</div>
      <div class="button_text">联系我们</div>
      <div class="content">
        <div class="list">
          <div class="item">
            <div>核心技术</div>
            <div class="title">AI唇声技术</div>
            <div class="title">AI动作捕捉</div>
          </div>
          <div class="item">
            <div>产品方案</div>
            <div class="title">超写实虚拟人制作</div>
            <div class="title">AI虚拟人信息播报产品</div>
            <div class="title">AI虚拟人电商带货产品</div>
            <div class="title">AI虚拟人直播产品</div>
          </div>
          <div class="item">
            <div>联系我们</div>
            <div class="title">商务合作：13575797493</div>
            <div class="title">
              江西总部：江西省南昌市红谷滩新区九龙大道VR产业园1号楼13楼
            </div>
            <div class="title">
              北京分部：北京市海淀区中关村南大街36号12号楼17层1706号
            </div>
            <div class="title">
              湖南分部：湖南省长沙市高新开发区麓云路100号兴工科技园12栋
            </div>
            <div class="title">
              杭州分部：浙江省杭州市余杭区仓前街道时尚万通城3幢1607A室
            </div>
          </div>
          <div class="itemimg">
            <div>
              <span class="title"> 联系我们 </span>
              <img src="../../assets/weix.jpg" />
            </div>
            <div>
              <span class="title"> 公众号 </span>
              <img src="../../assets/gonzhonhao.jpg" />
            </div>
            <div>
              <span class="title"> 视频号 </span>
              <img src="../../assets/weixshipinhao.jpg" />
            </div>
          </div>
        </div>
        <div class="footer">
          <img class="img" src="../../assets/icon.png" alt="" />
          <div class="text">隐私条款</div>
          <div class="text">关于我们</div>
          <div class="text">加入我们</div>
        </div>
        <div class="xian"></div>
        <div class="last">
          <img class="img1" src="../../assets/ICP.png" alt="" />
          <img class="img2" src="../../assets/police.png" alt="" />
          <div class="text">京公网安备 11010802042927号</div>
          <a class="text" href="https://beian.miit.gov.cn/" target="_blank">京ICP备2021032147号</a>
          <!-- <div class="text">京ICP备2021032147号</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Footer",
    data() {
      return {};
    },
    methods: {},
  };
</script>

<style lang="less" scoped>
  .box5 {
    height: 674px;
    background: url("../../assets/bg1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;

    .box5Index {
      width: 1200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
    }

    .title1 {
      font-size: 30px;
      margin-top: 80px;
    }

    .title2 {
      margin-top: 15px;
      font-size: 18px;
    }

    .button_text {
      font-size: 16px;
      margin-top: 30px;
      background: linear-gradient(-43deg, #6717cd 0%, #2871fa 100%) !important;
      border-radius: 6px;
      padding: 0 18px;
      line-height: 30px;
    }

    .content {
      margin-top: 80px;
      display: flex;
      width: 100%;
      flex-direction: column;

      .xian {
        height: 2px;
        background: #efefef;
        opacity: 0.21;
      }

      .list {
        display: flex;

        .item {
          text-align: left;
          margin-right: 100px;

          .title {
            font-size: 12px;
            opacity: 0.46;
            margin-top: 12px;
          }
        }

        .item:last-child {
          margin: 0;
        }

        .itemimg {
          width: 350px;
          display: flex;
          justify-content: space-between;

          img {
            width: 100px;
            height: 100px;
          }
        }
      }

      .last {
        height: 50px;
        display: flex;
        // 侧轴、主轴 单行居中
        align-items: center;
        justify-content: center;

        .img1 {
          height: 20px;
          widows: 20px;
        }

        .img2 {
          height: 20px;
          widows: 20px;
          margin-right: 16px;
          margin-left: 10px;
        }

        .text {
          margin-right: 10px;
          font-size: 14px;
          color: #666;
        }
      }

      .footer {
        height: 74px;
        display: flex;
        align-items: center;

        .img {
          height: 30px;
          margin-right: 56px;
        }

        .text {
          margin-right: 22px;
          font-size: 14px;
        }
      }
    }
  }
</style>
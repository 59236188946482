<template>
  <div class="mobileNav">
    <div class="navList">
      <!-- <img class="icon" src="../../assets/icon.png" alt="" /> -->
      <img
        @click="show = !show"
        class="icon2"
        src="../../assets/mobileIcon.png"
        alt=""
      />
    </div>
    <van-popup
      v-model="show"
      position="right"
      :style="{ height: '100vh', width: '80%' }"
      :close-on-click-overlay="false"
    >
      <div class="side">
        <div class="close">
          <img
            @click="show = !show"
            class="closeIcon"
            src="../../assets/x.png"
            alt=""
          />
        </div>
        <div class="sideList">
          <div
            class="sideItem"
            v-for="(item, index) in list"
            :key="index"
            :class="item.name == currentName ? 'active' : ''"
            @click="handleNav(item.path, item.name)"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      show: false,
      currentName: "",
      list: [
        {
          title: "首页",
          name: "MobileHome",
          path: "/mobile/home",
        },
        {
          title: "产品方案",
          name: "MobileProduct",
          path: "/mobile/product",
        },
        {
          title: "核心技术",
          name: "MobileCore",
          path: "/mobile/core",
        },
        {
          title: "智能硬件",
          name: "MobileIntelligent",
          path: "/mobile/intelligent",
        },
        {
          title: "关于我们",
          name: "MobileAbout",
          path: "/mobile/about",
        },
      ],
    };
  },

  watch: {
    "$route.name": {
      handler(newVal) {
        this.currentName = newVal;
        console.log(newVal);
      },
      immediate: true,
    },
  },

  methods: {
     handleNav(path, name) {
      if (this.$route.name !== name) {
        this.show = false
        this.$router.push({
          path,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.mobileNav {
  position: fixed;
  right: 0;
  z-index: 2;
  margin-top: 35px;
  .navList {
    display: flex;
    padding: 0 25px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .icon {
      width: 84px;
      height: 30px;
    }
    .icon2 {
      width: 35px;
      height: 35px;
    }
  }

  .popup {
    background: pink;
    position: absolute;
    top: 0;
  }
}

.side {
  background: url("../../assets/cebian.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;

  .close {
    height: 110px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .closeIcon {
      width: 20px;
      height: 20px;
      margin-right: 20px;
    }
  }

  .sideList {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .sideItem {
      height: 40px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #efefef;
      width: 90%;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding-left: 24px;
      margin-bottom: 10px;
    }
    .active {
      background: linear-gradient(-43deg, #6717cd 0%, #2871fa 100%) !important;
    }
  }
}
</style>

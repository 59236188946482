<template>
  <div class="index">
    <div class="header">
      <div class="content1">
        <div class="text_box">
          <div class="title1">数字虚拟人智能大屏</div>
          <div class="title2">
            数字人是基于人工智能的多模态人机交互系统。她形象丰富、动作自然、
            交互方式多样；适配多终端及系统；能自主交互问答，还能实现实时真人接管；是打
            造的未来行业超级员工，为企业节省人力资源成本，满足技术创新需求，助力城市数字化转型。
          </div>
        </div>
        <img src="../../assets/ai2.png" alt="" />
      </div>
    </div>
    <div class="content">
      <div class="width1200">
        <div class="text">
          <div class="item1">数字虚拟人智能透明屏</div>
          <div class="item2">9大功能介绍</div>
          <div class="list">
            <div style="margin-right: 60px">
              <div class="item_list" v-for="(item, index) in list" :key="index">
                <img class="icon" src="../../assets/yuan.png" alt="" />
                <div class="title">{{ item }}</div>
              </div>
            </div>
            <div>
              <div
                class="item_list"
                v-for="(item, index) in list1"
                :key="index"
              >
                <img class="icon" src="../../assets/yuan.png" alt="" />
                <div class="title">{{ item }}</div>
              </div>
            </div>
          </div>
        </div>
        <img class="ai2" src="../../assets/ai2.png" alt="" />
      </div>
    </div>
    <div class="box5">
      <div class="box5Index">
        <div class="title1">多种解决方案，满足不同场景的虚拟数字人应用需求</div>
        <div class="title2">留下联系方式，我们将为您提供一对一的资询服务</div>
        <div class="button_text">联系我们</div>
        <div class="content">
          <div class="list">
            <div class="item">
              <div>核心技术</div>
              <div class="title">AI唇声技术</div>
              <div class="title">AI动作捕捉</div>
            </div>
            <div class="item">
              <div>产品方案</div>
              <div class="title">超写实虚拟人制作</div>
              <div class="title">AI虚拟人信息播报产品</div>
              <div class="title">AI虚拟人电商带货产品</div>
              <div class="title">AI虚拟人直播产品</div>
            </div>
            <div class="item">
              <div>联系我们</div>
              <div class="title">商务合作：13575797493</div>
              <div class="title">
                江西总部：江西省南昌市红谷滩新区九龙大道VR产业园1号楼13楼
              </div>
              <div class="title">
                北京分部：北京市海淀区中关村南大街36号12号楼17层1706号
              </div>
              <div class="title">
                湖南分部：湖南省长沙市高新开发区麓云路100号兴工科技园12栋
              </div>
              <div class="title">
                杭州分部：浙江省杭州市余杭区仓前街道时尚万通城3幢1607A室
              </div>
            </div>
            <div class="itemimg">
              <div>
                <span class="title"> 联系我们 </span>
                <img src="../../assets/weix.jpg" />
              </div>
              <div>
                <span class="title"> 公众号 </span>
                <img src="../../assets/gonzhonhao.jpg" />
              </div>
              <div>
                <span class="title"> 视频号 </span>
                <img src="../../assets/weixshipinhao.jpg" />
              </div>
            </div>
          </div>
          <div class="footer">
            <img class="img" src="../../assets/icon.png" alt="" />
            <div class="text">隐私条款</div>
            <div class="text">关于我们</div>
            <div class="text">加入我们</div>
            <img class="img1" src="../../assets/ICP.png" alt="" />
            <img class="img2" src="../../assets/police.png" alt="" />
            <div class="text1">京公网安备 11010802042927号</div>
            <div class="text1">京ICP备2021032147号</div>
          </div>
          <div class="xian"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: ["问候接待", "智能讲解", "人物识别", "远程客服", "导航导览"],
      list1: ["互动问答", "信息播报", "内容推荐", "主动营销"],
    };
  },
  mounted() {
    const query = this.$route.query;
    const element = document.getElementsByClassName("header")[0];
    element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
  },
};
</script>

<style lang='less' scoped>
.index {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #000;
  .header {
    height: 800px;
    background: url("../../assets/bg5.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    .content1 {
      height: 400px;
      width: 1200px;
      display: flex;
      justify-content: space-between;
      img {
        object-fit: fill;
      }
      .text_box {
        text-align: left;
        color: #ffffff;
        font-family: Source Han Sans CN;
        font-weight: 400;
        width: 350px;

        .title1 {
          font-size: 30px;
        }
        .title2 {
          font-size: 16px;
          text-indent: 2em;
          margin-top: 60px;
          line-height: 40px;
        }
      }
    }

    .bg {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .content {
    height: 800px;
    background: url("../../assets/bg2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    .width1200 {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      .text {
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        text-align: left;
        .item1 {
          font-size: 30px;
        }
        .item2 {
          font-size: 18px;
          margin-top: 34px;
        }

        .list {
          display: flex;
          margin-top: 28px;
          font-size: 16px;
          .item_list {
            display: flex;
            height: 36px;
            align-items: center;
            .icon {
              width: 14px;
              height: 14px;
              margin-right: 6px;
            }
          }
        }
      }
    }
    .img1 {
      max-height: 100%;
      max-width: 100%;
    }
    .ai2 {
      height: 400px;
    }
  }
  .box5 {
    // // height: 674px;
    // background: url("../../assets/bg1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;

    .box5Index {
      width: 1200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
      height: 400px;
    }

    .title1 {
      font-size: 30px;
      // margin-top: 80px;
    }

    .title2 {
      // margin-top: 15px;
      font-size: 18px;
    }

    .button_text {
      font-size: 16px;
      margin-top: 30px;
      background: linear-gradient(-43deg, #6717cd 0%, #2871fa 100%) !important;
      border-radius: 6px;
      padding: 0 18px;
      line-height: 30px;
    }

    .content {
      // margin-top: 80px;
      display: flex;
      width: 100%;
      flex-direction: column;
      .xian {
        height: 2px;
        background: #efefef;
        opacity: 0.21;
      }
      .list {
        display: flex;

        .item {
          text-align: left;
          margin-right: 100px;
          .title {
            font-size: 12px;
            opacity: 0.46;
            margin-top: 12px;
          }
        }
        .item:last-child {
          margin: 0;
        }
        .itemimg {
          width: 350px;
          display: flex;
          justify-content: space-between;

          img {
            width: 100px;
            height: 100px;
          }
        }
      }
      .footer {
        height: 74px;
        display: flex;
        align-items: center;
        .img {
          height: 30px;
          margin-right: 56px;
        }

        .img1 {
          height: 20px;
          widows: 20px;
        }
        .img2 {
          height: 20px;
          widows: 20px;
          margin-right: 10px;
          margin-left: 10px;
        }

        .text1 {
          margin-right: 10px;
          font-size: 14px;
          color: #666;
        }

        .text {
          margin-right: 22px;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Pc/Home.vue'
import About from '../views/Pc/About.vue'
import Intelligent from '../views/Pc/Intelligent.vue'
import Core from '../views/Pc/Core.vue'
import Product from '../views/Pc/Product.vue'

import MobileHome from '../views/Mobile/Home.vue'
import MobileAbout from '../views/Mobile/About.vue'
import MobileIntelligent from '../views/Mobile/Intelligent.vue'
import MobileCore from '../views/Mobile/Core.vue'
import MobileProduct from '../views/Mobile/Product.vue'

Vue.use(VueRouter)

const routes = [
  /**
   * pc
   */
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  { //智能硬件
    path: '/intelligent',
    name: 'Intelligent',
    component: Intelligent
  },
  { //核心技术
    path: '/core',
    name: 'Core',
    component: Core
  },
  { //产品方案
    path: '/product',
    name: 'Product',
    component: Product
  },


  /**
   * h5
   */
  { //首页
    path: '/mobile/home',
    name: 'MobileHome',
    component: MobileHome
  },
  { //关于我们
    path: '/mobile/about',
    name: 'MobileAbout',
    component: MobileAbout
  },
  { //智能硬件
    path: '/mobile/intelligent',
    name: 'MobileIntelligent',
    component: MobileIntelligent
  },
  { //核心技术
    path: '/mobile/core',
    name: 'MobileCore',
    component: MobileCore
  },
  { //产品方案
    path: '/mobile/product',
    name: 'MobileProduct',
    component: MobileProduct
  },

]
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
<template>
  <div class="index">
    <div class="box1">
      <div class="content">
        <div class="text">
          <div class="title1">超高逼真数字虚拟人</div>
          <div class="list">
            <div class="itemList" v-for="(item, index) in list" :key="index">
              <img class="imgItem" :src="item.url" alt="" />
              <div class="itemTitle">{{ item.title }}</div>
            </div>
          </div>
        </div>
        <div class="img">
          <video
            autoplay
            loop
            width="588"
            height=""
            src="http://171.34.173.85:12888/static/videos/human_hdr.mp4"
          ></video>
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="content">
        <img class="img" src="../../assets/chanpin1.png" alt="" />
        <div class="text">
          <div class="title1">AI虚拟人信息播报产品</div>
          <div class="title2">语言合成，图像处理，智能翻译等人工智能技术。</div>
          <div class="title2">多语言的新闻播报，支持文本到视频的自动输出。</div>
        </div>
      </div>
    </div>
    <div class="box3">
      <div class="content">
        <div class="text">
          <div class="title1">AI虚拟人电商带货产品</div>
          <div class="title2">
            AI机器人直播带货所具有的优势是非常明显的，首先在这一类型的直播模式中不用真人出镜，也不用真人看守，这也就意味着有很多大众可以从事自己第一职业的同时开拓自己的第二职业，既不会浪费时间成本，又不会花费太多的心血，而在收益方面甚至能够抵得上上班的收入。对于一些小型企业来说，采用该类型的带货直播模式，则意味着有效降低成本开支，提升产品销售业绩。
          </div>
        </div>
        <img class="img" src="../../assets/chanpin3.png" alt="" />
      </div>
    </div>
    <div class="box4">
      <div class="content">
        <img class="img" src="../../assets/chanpin2.png" alt="" />
        <div class="text">
          <div class="title1">AI虚拟人直播产品</div>
          <div class="title2">
            在直播和短视频的庞大流量池下，利用AI虚拟人物技术+动态捕捉技术，在内容和营销上进行创新，这是一个让品牌在市场竞争中脱颖而出，抢占消费者心理的重要因素，利用虚拟形象做直播、短视频，可以更好的提升企业的品牌价值，让商家留住粉丝，刺激最终转化。
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Pc/Footer.vue";
export default {
  components: {
    Footer,
  },
  data() {
    return {
      list: [
        {
          url: require("../../assets/chanpin4.png"),
          title: "具有资产的数字员工",
        },
        {
          url: require("../../assets/chanpin5.png"),
          title: "企业宣传，品牌代言官，服务官",
        },
        {
          url: require("../../assets/chanpin6.png"),
          title: "完美形象永久服务",
        },
      ],
    };
  },

  mounted() {
    // console.log(this.$route.query);
    // const query = this.$route.query
    // const element = document.getElementsByClassName(`box${query.index}`)[0]
    // element.scrollIntoView({behavior:'smooth', inline: 'nearest'});
  },
};
</script>

<style lang="less" scoped>
.index {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: #fff;

  .box1 {
    height: 800px;
    background: url("../../assets/bg5.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      display: flex;
      width: 1200px;
      justify-content: space-between;
      .img {
        width: 588px;
        min-height: 354px;
      }
      .text {
        text-align: left;
        .title1 {
          font-size: 30px;
        }
        .list {
          .itemList {
            display: flex;
            align-items: center;
            margin-top: 50px;
            .imgItem {
              width: 30px;
              height: 30px;
              margin-right: 10px;
            }
            .itemTitle {
              font-size: 18px;
              line-height: 29px;
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
  .box2 {
    height: 800px;
    background: url("../../assets/bg2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      display: flex;
      width: 1200px;
      justify-content: space-between;
      .img {
        width: 460px;
        height: 572px;
      }

      .text {
        text-align: left;
        .title1 {
          font-size: 30px;
        }
        .title2 {
          font-size: 18px;
          font-weight: 400;
          color: #ffffff;
          opacity: 0.7;
          margin-top: 50px;
        }
      }
    }
  }
  .box3 {
    height: 800px;
    background: url("../../assets/bg1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      display: flex;
      width: 1200px;
      justify-content: space-between;
      .img {
        width: 460px;
        height: 572px;
      }
      .text {
        text-align: left;
        .title1 {
          font-size: 30px;
        }
        .title2 {
          font-size: 18px;
          text-align: center;
          max-width: 370px;
          font-weight: 400;
          color: #ffffff;
          line-height: 40px;
          text-indent: 2em;
          margin: 0 auto;
          margin-top: 50px;
        }
      }
    }
  }
  .box4 {
    height: 800px;
    background: url("../../assets/bg3.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      display: flex;
      width: 1200px;
      justify-content: space-between;
      .img {
        width: 460px;
        height: 572px;
      }

      .text {
        text-align: left;
        .title1 {
          font-size: 30px;
        }
        .title2 {
          font-size: 18px;
          max-width: 370px;
          font-weight: 400;
          color: #ffffff;
          margin-top: 50px;
          line-height: 40px;
          text-indent: 2em;
        }
      }
    }
  }
}
</style>
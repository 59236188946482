<template>
  <div class="home">
    <div class="box1">
      <img class="bg1" src="../../assets/home.png" alt="" />
      <div class="content">
        <div class="content_item">
          <div class="title1">
            DIGITAL <br />
            VIRTUAL EMPLOYEE
          </div>
        </div>
        <div class="content_item">
          <div class="title3">- SHARE 虚拟人主播</div>
          <div class="title2">
            专注提供 <br />
            数字虚拟人员工解决方案
          </div>
        </div>
      </div>
    </div>
    <div class="box3">
      <div class="content">
        <div class="text_box">
          <div class="title1">数字虚拟员工四大官</div>
          <div class="title2">Four Officials of Digital Virtual Workers</div>
        </div>
        <div class="list">
          <div class="item_box" @click="handleTo(1)">
            <div class="title1">CHIEF</div>
            <div class="title1">BRAND OFFICER</div>
            <div class="title2">首席品牌官</div>
          </div>
          <div class="item_box" @click="handleTo(4)">
            <div class="title1">CHIEF</div>
            <div class="title1">LIVE BROADCASTER</div>
            <div class="title2">首席直播官</div>
          </div>
          <div class="item_box" @click="handleTo(1)">
            <div class="title1">CHIEF</div>
            <div class="title1">SERVICE OFFICER</div>
            <div class="title2">首席服务官</div>
          </div>
          <div class="item_box" @click="handleTo(2)">
            <div class="title1">AIINFORMATION</div>
            <div class="title1">BROADCASTER</div>
            <div class="title2">AI信息播报官</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="box2_content">
        <div class="box2_text">
          <div class="title1">智能硬件</div>
          <div class="title2">SMART HARDWARE</div>
          <div class="box2_title">AI虚拟人互动大屏</div>
        </div>
        <div class="box2_img" @click="handleTo3()">
          <img class="img" src="../../assets/home1.png" alt="" />
        </div>
      </div>
    </div>
    <div class="box4">
      <div class="content">
        <div class="contentItem">
          <div class="text_box">
            <div class="title1">核心技术</div>
            <div class="title2">Core Technology</div>
          </div>
          <div class="list">
            <div class="item hoverImg" @click="handleTo2(1)">
              <img class="img_item" src="../../assets/tu1.png" alt="" />
              <div class="img_text">AI唇声技术</div>
            </div>
            <div class="item hoverImg" @click="handleTo2(2)">
              <img class="img_item" src="../../assets/tu2.png" alt="" />
              <div class="img_text">AI动作捕捉</div>
            </div>
            <div class="item hoverImg" @click="handleTo2(3)">
              <img class="img_item" src="../../assets/tu3.png" alt="" />
              <div class="img_text">音色克隆技术</div>
            </div>
          </div>
        </div>
        <div class="contentItem">
          <div class="text_box">
            <div class="title1">专属元宇宙数字资产</div>
            <div class="title2">EXCLUSIVE METAVERSE DIGITAL ASSETS</div>
          </div>
          <div class="list">
            <div class="item">
              <img class="img_item2" src="../../assets/shuzi2.png" alt="" />
              <div class="img_text">人物形象数字资产</div>
            </div>
            <div class="item">
              <img class="img_item2" src="../../assets/shuzi3.png" alt="" />
              <div class="img_text">声音数字资产</div>
            </div>
          </div>
          <div class="list">
            <div class="item">
              <img class="img_item2" src="../../assets/shuzi4.png" alt="" />
              <div class="img_text">人物周边（服装，发型等）</div>
            </div>
            <div class="item">
              <img class="img_item2" src="../../assets/shuzi5.png" alt="" />
              <div class="img_text">人物剧情数字资产</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部footer -->
    <Footer/>
    <!-- <div class="box5">
      <div class="box5Index">
        <div class="title1">多种解决方案，满足不同场景的虚拟数字人应用需求</div>
        <div class="title2">留下联系方式，我们将为您提供一对一的资询服务</div>
        <div class="button_text">联系我们</div>
        <div class="content">
          <div class="list">
            <div class="item">
              <div>核心技术</div>
              <div class="title">AI唇声技术</div>
              <div class="title">AI动作捕捉</div>
            </div>
            <div class="item">
              <div>产品方案</div>
              <div class="title">超写实虚拟人制作</div>
              <div class="title">AI虚拟人信息播报产品</div>
              <div class="title">AI虚拟人电商带货产品</div>
              <div class="title">AI虚拟人直播产品</div>
            </div>
            <div class="item">
              <div>联系我们</div>
              <div class="title">商务合作：13575797493</div>
              <div class="title">
                江西总部：江西省南昌市红谷滩新区九龙大道VR产业园1号楼13楼
              </div>
              <div class="title">
                北京分部：北京市海淀区中关村南大街36号12号楼17层1706号
              </div>
              <div class="title">
                湖南分部：湖南省长沙市高新开发区麓云路100号兴工科技园12栋
              </div>
              <div class="title">
                杭州分部：浙江省杭州市余杭区仓前街道时尚万通城3幢1607A室
              </div>
            </div>
            <div class="itemimg">
              <div>
                <span class="title"> 联系我们 </span>
                <img src="../../assets/weix.jpg" />
              </div>
              <div>
                <span class="title"> 公众号 </span>
                <img src="../../assets/gonzhonhao.jpg" />
              </div>
              <div>
                <span class="title"> 视频号 </span>
                <img src="../../assets/weixshipinhao.jpg" />
              </div>
            </div>
          </div>
          <div class="footer">
            <img class="img" src="../../assets/icon.png" alt="" />
            <div class="text">隐私条款</div>
            <div class="text">关于我们</div>
            <div class="text">加入我们</div>
            <img class="img1" src="../../assets/police.png" alt="">
            <div class="text">京公网安备 11010802042927号</div>
          </div>
          <div class="xian"></div>
        </div>
      </div>
    </div> -->

  </div>
</template>

<script>
import Footer from '@/components/Pc/Footer.vue'
export default {
  name: "Home",
  components: {
    Footer,
  },
  methods: {
    handleTo(index) {
      this.$router.push({
        path: "/product",
        query: {
          index,
        },
      });
    },
    handleTo2(index) {
      this.$router.push({
        path: "/core",
        query: {
          index,
        },
      });
    },
    handleTo3() {
      this.$router.push({
        path: "/intelligent",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  background: #000;
}
.box1 {
  position: relative;
  .bg1 {
    object-fit: fill;
    width: 100%;
  }

  .content {
    position: absolute;
    display: flex;
    width: 1200px;
    height: 80%;
    top: 10%;
    left: 50%;
    margin-left: -600px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .content_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title1 {
        text-align: left;
        font-size: 25px;
        font-family: Ubuntu;
        font-weight: bold;
        color: #efefef;
      }
      .title2 {
        text-align: right;
        font-size: 25px;
        font-family: Ubuntu;
        font-weight: bold;
        color: #efefef;
      }
      .title3 {
        font-size: 18px;
        color: #fff;
      }
    }
  }
}
.box2 {
  height: 674px;
  background: url("../../assets/bg2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 50px;
  box-sizing: border-box;
  .box2_text {
    text-align: left;
    margin-right: 120px;
    position: relative;
    margin-top: -90px;
    .title1 {
      font-size: 30px;
    }
    .title2 {
      font-size: 18px;
    }
  }

  .box2_content {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    .box2_title {
      line-height: 100px;
      font-size: 18px;
      opacity: 0.7;
    }
    .box2_img {
      .img {
        width: 700px;
        height: 460px;
      }
    }
    .box2_img :hover {
      transform: scale(1.05);
    }
  }
}
.box3 {
  height: 674px;
  background: url("../../assets/bg1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #fff;
  display: flex;
  justify-content: center;
  .content {
    display: flex;
    flex-direction: column;
    margin-top: 56px;
    width: 1200px;
    .text_box {
      text-align: left;
      .title1 {
        font-size: 30px;
      }
      .title2 {
        font-size: 18px;
      }
    }
    .list {
      margin-top: 45px;
      display: flex;
      justify-content: center;
      .item_box {
        text-align: left;
        width: 170px;
        background: #000;
        margin-right: 100px;
        height: 450px;
        padding: 15px;
        .title1 {
          font-size: 16px;
        }
        .title2 {
          margin-top: 10px;
          font-size: 18px;
        }
      }
      .item_box:hover {
        transform: scale(1.05);
      }
      .item_box:last-child {
        margin: 0;
      }
      .item_box:nth-child(1) {
        background: url("../../assets/xuni1.png");
        background-size: cover;
        background-repeat: no-repeat;
      }
      .item_box:nth-child(2) {
        background: url("../../assets/xuni2.png");
        background-size: cover;
        background-repeat: no-repeat;
      }
      .item_box:nth-child(3) {
        background: url("../../assets/xuni3.png");
        background-size: cover;
        background-repeat: no-repeat;
      }
      .item_box:nth-child(4) {
        background: url("../../assets/xuni4.png");
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }
}
.box4 {
  height: 874px;
  background: url("../../assets/bg3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;

  .content {
    display: flex;
    width: 1200px;
    display: flex;
    flex-direction: column;
    .contentItem {
      margin-top: 50px;
      display: flex;
      flex-direction: column;

      .list {
        display: flex;
        justify-content: center;
        margin-top: 50px;
        .item {
          width: 200px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 200px;
          .img_item {
            width: 192px;
            height: 132px;
          }
          .img_item2 {
            width: 94px;
            height: 94px;
          }
          .img_text {
            margin-top: 10px;
          }
        }
        .item:last-child {
          margin-right: 0;
        }
      }
      .text_box {
        text-align: left;
        .title1 {
          font-size: 30px;
        }
        .title2 {
          font-size: 18px;
        }
      }
    }
  }
}

.hoverImg :hover {
  cursor: pointer !important;
  transform: scale(1.05);
}
</style>

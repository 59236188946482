<template>
  <div class="index">
    <div class="box1">
      <div class="indexTitle">
        <div class="title1">AI唇声技术</div>
        <div class="title2">AI LIP SOUND TECHNOLOGY</div>
      </div>
      <div class="list">
        <div class="itemList">
          <div class="item">
            <img class="item_img" src="../../assets/item1.png" alt="" />
            <div class="item_title">
              精准识别语音<br />去掉静音成分<br />获取有效语音
            </div>
          </div>
          <div class="item">
            <img class="item_img" src="../../assets/item2.png" alt="" />
            <div class="item_title">
              高效降噪<br />处理音频内容<br />ASR识别更精准<br />减少资源浪费
            </div>
          </div>
        </div>
        <div class="itemList">
          <div class="item">
            <img class="item_img" src="../../assets/item3.png" alt="" />
            <div class="item_title">
              深度学习技术合成逼真语音<br />
              体验自然生动<br />速度快<br />自然流畅
            </div>
          </div>
          <div class="item">
            <img class="item_img" src="../../assets/item4.png" alt="" />
            <div class="item_title">支持多种语言方案</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="indexTitle">
        <div class="title1">AI动作捕捉</div>
        <div class="title2">AI MOTION CAPTURE</div>
      </div>
      <div class="video">
        动作捕捉是对物体运动进行记录的一种手段，从技术原理上分为光学、惯性、视觉这主要的三类。
      </div>
      <img class="videoImg" src="../../assets/video2.png" alt="" />
    </div>
    <div class="box3">
      <div class="title1">音色克隆技术</div>
      <div class="title2">SOUND CLONING TECHNOLOGY</div>
      <div class="title3">
        “声音复刻”又称“声音克隆”，是语音合成技术（TTS ，Text To
        Speech）的个性化应用，用户可通过少量的录音进行模型训练，得到与用户本人在音色和发音风格上非常相似的声音模型，快速“复刻”个性化声音，该“复刻”声音可使用在讲故事、播天气、读小说、导航播报等功能场景。
      </div>
      <img src="../../assets/new.png" alt="" class="box3Img" />
    </div>
    <div class="itemimg">
      <div>
        <span class="title"> 联系我们 </span>
        <img src="../../assets/weix.jpg" />
      </div>
      <div>
        <span class="title"> 公众号 </span>
        <img src="../../assets/gonzhonhao.jpg" />
      </div>
      <div>
        <span class="title"> 视频号 </span>
        <img src="../../assets/weixshipinhao.jpg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="less" scoped>
.index {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100%;
}
.box1 {
  height: 600px;
  background: url("../../assets/bg5h5.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0 25px;
  .indexTitle {
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    margin: 35px 0;
    text-align: left;

    .title1 {
      font-size: 20px;
    }

    .title2 {
      font-size: 12px;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    .itemList {
      display: flex;
      margin-top: 50px;
      .item {
        flex: 1;
        .item_title {
          margin-top: 20px;
          font-size: 13px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          line-height: 23px;
          opacity: 0.7;
        }
        .item_img {
          width: 45px;
          height: 45px;
        }
      }
    }
  }
}
.box2 {
  height: 600px;
  background: url("../../assets/zhinengh5.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  .indexTitle {
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    margin: 45px 0;
    text-align: left;

    .title1 {
      font-size: 20px;
    }

    .title2 {
      font-size: 12px;
    }
  }
  .video {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    line-height: 28px;
    margin-bottom: 60px;
    text-align: left;
    text-indent: 2em;
  }
  .videoImg {
    height: 310px;
    object-fit: fill;
  }
}
.box3 {
  height: 547px;
  background: #1a1a1a;
  padding: 0 25px;
  color: #fff;
  text-align: left;
  .title1 {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    margin-top: 40px;
  }
  .title2 {
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
  }
  .title3 {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    margin-top: 20px;
    text-indent: 2em;
    line-height: 28px;
  }
  .box3Img {
    height: 180px;
    margin-top: 20px;
  }
}
.itemimg {
  background-color: #1a1a1a;
  color: #fff;
  width: 100vw;
  display: flex;
  justify-content: space-between;

  img {
    width: 100px;
    height: 100px;
  }
}
</style>

<template>
  <div class="about">
    <div class="indexTitle">公司简介</div>
    <div class="content">
      <div class="title">
        北京小核桃科技有限公司成立于2015年12月，公司新四板挂牌代码：160464，公司荣获国家高新企业，科技型中小企业认证，公司位于中国北京市海淀区中关村南大街36号。公司团队来自于华为，中兴等知名企业，公司70%以上为研发人员。
      </div>
      <div class="title">
        我司研发打造的未来行业超级员工，为企业节省人力资源成本，满足技术创新需求，助力政府，企事业单位数字化转型。小核桃数字虚拟人基于人工智能的多模态人机交互系统。其形象丰富、动作自然、交互方式多样;适配多终端及系统;能自主交互问答，还能实现实时真人接管。数字人目前已在泛娱乐、金融、文旅等多个行业渗透应用,不同功能的数字人可赋能不同领域。
      </div>
    </div>
    <div class="itemimg">
      <div>
        <span class="title"> 联系我们 </span>
        <img src="../../assets/weix.jpg" />
      </div>
      <div>
        <span class="title"> 公众号 </span>
        <img src="../../assets/gonzhonhao.jpg" />
      </div>
      <div>
        <span class="title"> 视频号 </span>
        <img src="../../assets/weixshipinhao.jpg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="less" scoped>
.about {
  background: url("../../assets/aboutH5.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 100vh;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}
.indexTitle {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 40px;
  margin: 35px 0;
  padding-left: 25px;
  text-align: left;
}
.content {
  text-align: left;
  padding: 0 25px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 28px;
  .title {
    text-indent: 2em;
  }
}
.itemimg {
  margin-top: 200px;
  color: #fff;
  width: 100vw;
  display: flex;
  justify-content: space-between;

  img {
    width: 100px;
    height: 100px;
  }
}
</style>
